.react-select__control {
  /* padding: 0.375rem 2.25rem 0.375rem 0.75rem; */
  /* -moz-padding-start: calc(0.75rem - 3px); */
  /* font-size: 1rem; */
  font-weight: 400 !important;
  color: var(--bs-body-color) !important;
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-radius: 0.375rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-height: unset !important;
}

.react-select__control--is-focused {
  border-color: #86b7fe !important;
  box-shadow: 0 0 0 0.05rem rgba(13, 110, 253, 0.25) !important;
}

.react-select__control--is-disabled {
  background-color: var(--bs-secondary-bg) !important;
  opacity: 1 !important;
}

[data-bs-theme=dark] .react-select__control {
  font-weight: inherit !important;
}

.is-invalid .react-select__control {
  border-color: var(--bs-form-invalid-border-color) !important;
}

.is-invalid .react-select__control--is-focused {
  border-color: var(--bs-form-invalid-border-color) !important;
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25) !important;
}

.react-select__indicator-separator {
  /* display: none; */
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  background-color: var(--bs-border-color) !important;
}

.react-select__indicator {
  padding: 2px 5px !important;
  color: var(--bs-body-color) !important;
}

.react-select__menu {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.react-select__single-value,
.react-select__input-container {
  color: inherit !important;
}

.react-select__option {
  padding: 2px 12px !important;
  font-weight: 400 !important;
}

[data-bs-theme=dark] .react-select__option {
  font-weight: 500 !important;
}

.react-select__option--is-selected,
.react-select__option--is-focused {
  background-color: var(--primarycolor) !important;
}