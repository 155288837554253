svg.logo .logo-stroke {
  stroke: #1F3160;
}

svg.logo .logo-fill {
  fill: #1F3160;
}

[data-bs-theme=dark] svg.logo .logo-stroke {
  stroke: #2d4c8a;
}

[data-bs-theme=dark] svg.logo .logo-fill {
  fill: #2d4c8a;
}